* {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  font-size: 100%;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  background: black;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 10px;
  background: linear-gradient(#92053b, #92053b00);
  color: white;
  img {
    vertical-align: center;
  }
}

video {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

h1,
h2,
h3 {
  font-size: 1rem;
  margin: 0;
}

p {
  font-size: 1rem;
  margin: 0;
}

a,
a:visited {
  color: inherit;
}

#searching {
  animation: blinkAnimation 1.5s linear infinite;
}

@keyframes blinkAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px 15px 25px;
  background: linear-gradient(#92053b00, #92053b);
}

#mediaLink {
  cursor: pointer;
  border: solid 1px white;
  padding: 10px 30px;
  border-radius: 9999px;
  color: white;
  opacity: 0;
  transition: opacity 0.5s;
}

#mediaLink:hover {
  background: white;
  color: black;
}

#media {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#media video {
  height: calc(100% - 50px);
  top: 50px;
  bottom: 0;
  object-fit: contain;
}

#media-page {
  header {
    display: flex;
  }
}

.back {
  display: block;
  width: 25px;
  height: 20px;
  background: url(assets/back-arrow.svg) no-repeat;
  background-size: 20px 20px;
  background-position: 5px 0;
  font-size: 0;
  margin-right: 15px;
  transition: 0.2s;
  &:hover {
    background-position: 0 0;
  }
}
